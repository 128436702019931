import validate from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/middleware/auth.global.ts";
import manifest_45route_45rule from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/middleware/authenticated.ts"),
  unauthenticated: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/middleware/unauthenticated.ts")
}
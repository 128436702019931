export interface Highligth {
    title: string;
    icon: string;
    description: string;
}

export const HIGHLIGTHS: Highligth[] = [
    {
        title: 'Seguro',
        icon: '/img/highligths/seguro.svg',
        description: 'Nuestro compromiso es proteger tus datos con los mas altos estándares de seguridad y confidencialidad.',
    },
    {
        title: 'Fácil',
        icon: '/img/highligths/facil.svg',
        description: 'Te ofrecemos un proceso rápido y simple, sin complicaciones para adquirir los seguros que necesites.',
    },
    {
        title: 'Digital',
        icon: '/img/highligths/digital.svg',
        description: 'Consulta, reclama o contrata cuando necesites, cómodamente desde tu móvil o computadora.',
    },
    {
        title: 'Confiable',
        icon: '/img/highligths/confiable.svg',
        description: '168,419 pólizas procesadas a través de todos nuestros canales desde nuestro lanzamiento en el 2020.',
    },
    {
        title: 'IA',
        icon: '/img/highligths/ia.svg',
        description: 'Nuestro comparador de seguros de auto fue desarrollado con la ayuda de la Inteligencia Artifcial.',
    },
    {
        title: 'Integrado',
        icon: '/img/highligths/integrado.svg',
        description: 'Nos comunicamos en tiempo real con las principales aseguradoras del mercado.',
    },
]


import { StoreProcessType, type Product, type ProductPlan } from '../products'

export const SERIOUS_DISEAS: Product = {
  productId: 62,
  name: 'Enfermedades Graves',
  alias: 'Enfermedades',
  tagline: 'preparado para lo que venga',
  code: 'enfermedades-graves',
  process: 'PURCHASE',
  bannerUrl: '/img/product-cards/enfermedades-graves.png',
  iconUrl: '/img/product-icons/enfermedades-graves.png',
  description:
    'Este seguro cuenta con un respaldo diseñado para brindarte compensación económica inmediata en caso de ser diagnosticado con una de las siguientes enfermedades catastróficas.',
  compliances: [
    'Cáncer',
    'Infarto Miocardio',
    'Accidente Vascular Cerebral (ACV)',
    'Transplante de Órgano Mayor',
    'Insuficiencia Renal Crónica',
    'Ceguera',
    'Cirugía Puente Aortocoronario',
    'Esclerosis Múltiple',
    'Neurocirugía',
    'Parálisis',
    'Politraumatísmo',
    'Quemaduras Múltiples',
    'VIH',
  ],
  exceptions: [],
  requirements: [
    'Edad minimo de ingreso: 18 años.',
    'Edad maxima de ingreso: 65 años.',
    'Edad maxima de permanencia: 70 años.',
    'Período de carencia: 6 meses, excepto politraumatísmo y quemaduras múltiples.',
  ],
  insurances: ['humano'],
  highlights: [
    {
      title: '',
      artWork: '',
      description:
        'Cáncer, Infarto Miocardio, Accidente Vascular Cerebral (ACV), Transplante de Órgano Mayor',
    },
    {
      title: '',
      artWork: '',
      description: 'Insuficiencia Renal Crónica, Ceguera, Cirugía Puente Aortocoronario',
    },
    {
      title: '',
      artWork: '',
      description: 'Esclerosis Múltiple, Neurocirugía, Parálisis',
    },
    {
      title: '',
      artWork: '',
      description: 'Politraumatísmo, Quemaduras Múltiples, VIH',
    },
  ],
  plans: [
    {
      name: 'LITE',
      code: 'LITE',
      description: '',
      highlights: {
        description: [
          {
            label: 'COMPENSACIÓN:',
            detail: 'RD$250,000',
          },
        ],
      },
      price: 116,
      subscription: 'monthly',
    },
    {
      name: 'FULL',
      code: 'FULL',
      description: '',
      highlights: {
        description: [
          {
            label: 'COMPENSACIÓN:',
            detail: 'RD$500,000',
          },
        ],
      },
      price: 232,
      subscription: 'monthly',
    },
    {
      name: 'ULTRA',
      code: 'ULTRA',
      description: '',
      highlights: {
        description: [
          {
            label: 'COMPENSACIÓN:',
            detail: 'RD$750,000',
          },
        ],
      },
      price: 349,
      subscription: 'monthly',
    },
  ],
}

import type { ProductCode } from '.'

export const HOME_META_TAGS: Parameters<typeof useSeoMeta>[0] = {
  title: 'CONTIGO | Seguros Que Hacen MATCH CONTIGO',
  description:
    'Encuentra el seguro que mejor se adapta a ti con Contigo Seguros. Descubre opciones personalizadas que hacen match con tus necesidades. ¡Es sencillo y rápido!',
  ogUrl: 'https://www.contigo.do',
  ogImage: 'https://www.contigo.do/img/banners/banner_auto.png',
  ogSiteName: 'Contigo Seguros',
  ogDescription:
    'Descubre el seguro perfecto para ti con Contigo Seguros. Personaliza tu cobertura en minutos y obtén el seguro que hace match con tus necesidades. ¡Empieza hoy mismo!',
}

export const PRODUCT_META_TAGS: Record<ProductCode, Parameters<typeof useSeoMeta>[0]> = {
  auto: {
    title: 'CONTIGO | Comparador de seguro de auto',
    description: 'Compara entre las mejores aseguradoras en minutos.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/auto',
    ogImage: 'https://www.contigo.do/img/product-cards/auto.png',
    ogSiteName: 'Contigo Seguros',
  },
  'tech-movile': {
    title: 'CONTIGO | Seguro de Móvil: Protección Total',
    description:
      '¿Te imaginas un día sin tu móvil? Nuestro seguro te brinda cobertura ante cualquier daño físico o del sistema. Incluye mano de obra para la reparación con expertos.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/tech-movile',
    ogImage: 'https://www.contigo.do/img/product-cards/seguro-gamer.png',
    ogSiteName: 'Contigo Seguros',
  },
  'tech-home': {
    title: 'CONTIGO | Seguro de Móvil: Protección Total',
    description:
      '¿Te imaginas un día sin tu móvil? Nuestro seguro te brinda cobertura ante cualquier daño físico o del sistema. Incluye mano de obra para la reparación con expertos.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/tech-home',
    ogImage: 'https://www.contigo.do/img/product-cards/tech-home.png',
    ogSiteName: 'Contigo Seguros',
  },
  vida: {
    title: 'CONTIGO | Seguro de Vida: Protección para tu Familia',
    description:
      'Planifica y destina un futuro para tus seres queridos con nuestro Seguro de Vida. Protección ideal en caso de fallecimiento inesperado o invalidez.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/vida',
    ogImage: 'https://www.contigo.do/img/product-cards/vida.png',
    ogSiteName: 'Contigo Seguros',
  },
  viajes: {
    title: 'CONTIGO | Seguro de Viaje: Tranquilidad en tus Viajes',
    description:
      'Deseamos que tengas un viaje perfecto, ya sea de placer o negocios. Disfruta tu estadía con tranquilidad y libre de preocupaciones con nuestro Seguro de Viaje.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/viajes',
    ogImage: 'https://www.contigo.do/img/product-cards/viajes.png',
    ogSiteName: 'Contigo Seguros',
  },
  bici: {
    title: 'CONTIGO | Seguro de Bicicleta: Protección Completa',
    description:
      'Si tu bici es tu medio de transporte favorito, tenemos el producto ideal. Nuestro seguro de bici ofrece protección ante cualquier daño, robo o pérdida total.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/bici',
    ogImage: 'https://www.contigo.do/img/product-cards/bici.png',
    ogSiteName: 'Contigo Seguros',
  },
  hogar: {
    title: 'CONTIGO | Seguro de Hogar: Protección para tu Casa',
    description:
      'Protege tu casa ante todo. Nuestro Seguro de Hogar provee cobertura a inmuebles y pertenencias personales, brindando tranquilidad y protección total.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/hogar',
    ogImage: 'https://www.contigo.do/img/product-cards/hogar.png',
    ogSiteName: 'Contigo Seguros',
  },
  mascotas: {
    title: 'CONTIGO | Seguro para Mascotas: Protección y Asistencia',
    description:
      'Protege a tus amigos incondicionales con nuestro seguro para mascotas. Cobertura y asistencia para los gastos médicos que exige el cuidado de tu mascota.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/mascotas',
    ogImage: 'https://www.contigo.do/img/product-cards/mascotas.png',
    ogSiteName: 'Contigo Seguros',
  },
  moto: {
    title: 'CONTIGO | Seguro de Moto: Protección Personalizada',
    description:
      'Nuestro seguro de moto te permite elegir la opción que más se ajuste a ti. Protección y tranquilidad ante cualquier situación en el camino.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/moto',
    ogImage: 'https://www.contigo.do/img/product-cards/moto.png',
    ogSiteName: 'Contigo Seguros',
  },
  funeraria: {
    title: 'CONTIGO | Seguro de Asistencia Familiar: Tranquilidad para tu Familia',
    description:
      'Nuestro plan de asistencia familiar brinda apoyo integral a la familia en caso de fallecimiento. Servicios de asistencia para proveer tranquilidad en momentos difíciles.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/funeraria',
    ogImage: 'https://www.contigo.do/img/product-cards/funeraria.png',
    ogSiteName: 'Contigo Seguros',
  },
  accidentes: {
    title: 'CONTIGO | Seguro de Accidentes Personales: Protección Económica',
    description:
      'En caso de fallecimiento accidental, desmembramiento o incapacidad, nuestro seguro de accidentes personales brinda un beneficio económico a los beneficiarios designados.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/accidentes',
    ogImage: 'https://www.contigo.do/img/product-cards/accidentes.png',
    ogSiteName: 'Contigo Seguros',
  },
  'enfermedades-graves': {
    title: 'CONTIGO | Seguro de Enfermedades Catastróficas: Compensación Inmediata',
    description:
      'Nuestro seguro de enfermedades catastróficas te brinda compensación económica inmediata en caso de ser diagnosticado con una enfermedad grave. Protección cuando más lo necesitas.',
    ogType: 'website',
    ogUrl: 'https://www.contigo.do/store/enfermedades-graves',
    ogImage: 'https://www.contigo.do/img/product-cards/enfermedades-graves.png',
    ogSiteName: 'Contigo Seguros',
  },
}

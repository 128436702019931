import revive_payload_client_a7UfGNs3X3 from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JKSMq6BPXK from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SCemAp5J7w from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_a5JdvlZgAn from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.24.4_vite@5.4.10_@types+node@22.9.0_sass@1.77_nqelrhq4umjcjom4dzww2tpzwu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import nuxt_plugin_T3pV8jljet from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-open-fetch@0.9.1_magicast@0.3.5_rollup@4.24.4_typescript@5.5.4/node_modules/nuxt-open-fetch/dist/runtime/nuxt-plugin.mjs";
import payload_client_VBEa1OxGg8 from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_gK2IBujuya from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_t6JL4xBxhg from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_u8dFBZsYZ3 from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_zugNeFs8kp from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_o2fyyczyiymlvhglhlw4wzxyue/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_qg6MYRFsd5 from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-viewport@2.1.5_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.5.4_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_DfaZdxDAPq from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.0.4_@babel+parser@7.26.2_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.5.4_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import session_client_F0WT76Q1rR from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-auth-utils@0.3.5_magicast@0.3.5_rollup@4.24.4/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import primevue_7rYYRZQLyx from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/plugins/primevue.ts";
export default [
  revive_payload_client_a7UfGNs3X3,
  unhead_JKSMq6BPXK,
  router_SCemAp5J7w,
  _0_siteConfig_a5JdvlZgAn,
  nuxt_plugin_T3pV8jljet,
  payload_client_VBEa1OxGg8,
  navigation_repaint_client_gK2IBujuya,
  check_outdated_build_client_t6JL4xBxhg,
  chunk_reload_client_u8dFBZsYZ3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zugNeFs8kp,
  plugin_client_qg6MYRFsd5,
  primevue_plugin_egKpok8Auk,
  plugin_client_DfaZdxDAPq,
  session_client_F0WT76Q1rR,
  primevue_7rYYRZQLyx
]
import { StoreProcessType, type Product, type ProductPlan } from '../products'

export const PET: Product = {
  productId: 7,
  name: 'Mascotas',
  tagline: 'cuida a tu mejor amigo',
  code: 'mascotas',
  process: 'PURCHASE',
  bannerUrl: '/img/product-cards/mascotas.png',
  iconUrl: '/img/product-icons/mascotas.png',
  description:
    'Este plan está concebido para protección y asistencia a tus amigos incondicionales y principal fuente de felicidad, tus mascotas. Sabemos que a veces es difícil cubrir los gastos médicos que el cuidado de tu mascota exige. Para esto, estamos contigo.',
  compliances: [],
  exceptions: [
    'Reclamaciones cuyo hecho ocurra dentro del periodo de carencia',
    'Gastos veterinarios por enfermedad si es menor de 3 meses o mayor a 12 años',
    'Por inobservancia de las recomendaciones del veterinario',
    'Enfermedades infecciosas que pueda sufrir si no tiene cardnet de vacunación al día',
    'Procedimientos no realizados por veterinarios registrados en COLVET',
  ],
  requirements: [
    'Mascotas: Perros y gatos',
    'Edad minimo de ingreso: 3 meses.',
    'Edad maxima de ingreso: 8 años.',
    'Edad maxima de permanencia: 12 años.',
  ],
  insurances: ['sura'],
  highlights: [
    {
      title: 'Emergencias',
      artWork: '/img/product-traits/pet/emergencia.svg',
      description: 'Cubre visitas de emergencia a causa de enfermedad o accidentes.',
    },
    {
      title: 'Asistencias',
      artWork: '/img/product-traits/pet/asistencias.svg',
      description: 'Ofrece servicios de Asistencia Legal, orientación médica, baño y peluquería.',
    },
    {
      title: 'Hostpital',
      artWork: '/img/product-traits/pet/hospital.svg',
      description:
        'Cubre los gastos de hospitalización en las clínicas veterinarias afiliadas a COLVET. ',
    },
    {
      title: 'Fallecimiento',
      artWork: '/img/product-traits/pet/fallecimiento.svg',
      description: 'Asistencia para entierro o cremación de la mascota en caso de fallecimiento.',
    },
  ],
  plans: [
    {
      name: 'LITE',
      code: 'LITE',
      description: '',
      highlights: {
        title: 'Especificaciones',
        description: [
          {
            label: 'RESPONSABILIDAD CIVIL:',
            detail: 'RD$100,000',
          },
          {
            label: 'EMERGENCIAS:',
            detail: 'RD$2,000',
          },
          {
            label: 'CIRUGÍAS:',
            detail: 'RD$5,000',
          },

          {
            label: 'HOSPITALIZACIÓN:',
            detail: 'RD$7,500',
          },
          {
            label: 'EXAMEN LABORATORIO:',
            detail: 'RD$1,500',
          },
          {
            label: 'EXAMEN IMAGEN',
            detail: 'RD$1,250',
          },
          {
            label: 'TRATAMIENTO ODONTOLÓGICO:',
            detail: 'RD$500',
          },
          {
            label: 'ASISTENCIA FUNERARIA:',
            detail: 'RD$4,500',
          },
          {
            label: 'HOTEL - VIAJE DEL CLIENTE:',
            detail: 'RD$2,000',
          },
          {
            label: 'TRANSPORTE DEL PET VETERINARIO EN CASO DE ACCIDENTE:',
            detail: 'RD$900',
          },
          {
            label: 'TRANSPORTE DEL PET EN CASO DE LOCALIZACIÓN:',
            detail: 'RD$900',
          },
          {
            label: 'ORIENTACIÓN MÉDICA VÍA TELEFÓNICA:',
            detail: 'Sí',
          },
        ],
      },
      price: 406,
      subscription: 'monthly',
    },
    {
      name: 'FULL',
      code: 'FULL',
      description: '',
      highlights: {
        title: 'Especificaciones',
        description: [
          {
            label: 'INCLUYE TODOS LOS BENEFICIOS DE MORADO',
            type: 'NOTE',
          },
          {
            label: 'CIRUGÍAS:',
            detail: 'RD$7,500',
          },
          {
            label: 'HOSPITALIZACIÓN:',
            detail: 'RD$10,000',
          },
          {
            label: 'EXAMEN IMAGEN:',
            detail: 'RD$1,500',
          },
          {
            label: 'IMPLANTACIÓN DE CHIP:',
            detail: 'RD$1,000',
          },
          {
            label: 'APLICACIÓN DE VACUNA:',
            detail: 'RD$1,000',
          },
          {
            label: 'TRANSPORTE Y HOTEL- ACCIDENTE O ENFERMEDAD DEL DUEÑO:',
            detail: 'RD$2,000',
          },
        ],
      },
      price: 588,
      subscription: 'monthly',
    },
    {
      name: 'ULTRA',
      code: 'ULTRA',
      description: '',
      highlights: {
        title: 'Especificaciones',
        description: [
          {
            label: 'INCLUYE TODOS LOS BENEFICIOS DE PLATINUM',
            type: 'NOTE',
          },
          {
            label: 'CIRUGÍAS:',
            detail: 'RD$10,000',
          },
          {
            label: 'TRATAMIENTO ODONTOLÓGICO:',
            detail: 'RD$1,000',
          },
          {
            label: 'APLICACIÓN DE VACUNA:',
            detail: 'RD$1,250',
          },
          {
            label: 'PARTO:',
            detail: 'RD$3,000',
          },
          {
            label: 'CASTRACIÓN:',
            detail: 'RD$5,000',
          },
          {
            label: 'CONSULTA RUTINARIA:',
            detail: 'RD$1,000',
          },
        ],
      },
      price: 754,
      subscription: 'monthly',
    },
  ],
}

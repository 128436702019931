import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPvAutoComplete, LazyPvCalendar, LazyPvCascadeSelect, LazyPvChips, LazyPvColorPicker, LazyPvDropdown, LazyPvFloatLabel, LazyPvFluid, LazyPvIconField, LazyPvInputChips, LazyPvInputGroup, LazyPvInputGroupAddon, LazyPvInputIcon, LazyPvInputOtp, LazyPvInputSwitch, LazyPvKnob, LazyPvListbox, LazyPvMultiSelect, LazyPvRating, LazyPvSelectButton, LazyPvSlider, LazyPvToggleButton, LazyPvToggleSwitch, LazyPvTreeSelect, LazyPvButtonGroup, LazyPvSpeedDial, LazyPvSplitButton, LazyPvColumn, LazyPvRow, LazyPvColumnGroup, LazyPvDataTable, LazyPvDataView, LazyPvOrderList, LazyPvOrganizationChart, LazyPvPaginator, LazyPvPickList, LazyPvTree, LazyPvTreeTable, LazyPvTimeline, LazyPvVirtualScroller, LazyPvAccordion, LazyPvAccordionPanel, LazyPvAccordionHeader, LazyPvAccordionContent, LazyPvAccordionTab, LazyPvCard, LazyPvDeferredContent, LazyPvDivider, LazyPvFieldset, LazyPvPanel, LazyPvScrollPanel, LazyPvSplitter, LazyPvSplitterPanel, LazyPvStepper, LazyPvStepList, LazyPvStep, LazyPvStepItem, LazyPvStepPanels, LazyPvStepPanel, LazyPvTabView, LazyPvTabs, LazyPvTabList, LazyPvTab, LazyPvTabPanels, LazyPvTabPanel, LazyPvToolbar, LazyPvConfirmDialog, LazyPvConfirmPopup, LazyPvDialog, LazyPvDrawer, LazyPvDynamicDialog, LazyPvOverlayPanel, LazyPvPopover, LazyPvSidebar, LazyPvFileUpload, LazyPvBreadcrumb, LazyPvContextMenu, LazyPvDock, LazyPvMenu, LazyPvMenubar, LazyPvMegaMenu, LazyPvPanelMenu, LazyPvSteps, LazyPvTabMenu, LazyPvTieredMenu, LazyPvMessage, LazyPvInlineMessage, LazyPvToast, LazyPvCarousel, LazyPvGalleria, LazyPvImage, LazyPvAvatar, LazyPvAvatarGroup, LazyPvBadge, LazyPvBlockUI, LazyPvChip, LazyPvInplace, LazyPvMeterGroup, LazyPvOverlayBadge, LazyPvScrollTop, LazyPvSkeleton, LazyPvProgressBar, LazyPvProgressSpinner, LazyPvTag, LazyPvTerminal } from '#components'
const lazyGlobalComponents = [
  ["PvAutoComplete", LazyPvAutoComplete],
["PvCalendar", LazyPvCalendar],
["PvCascadeSelect", LazyPvCascadeSelect],
["PvChips", LazyPvChips],
["PvColorPicker", LazyPvColorPicker],
["PvDropdown", LazyPvDropdown],
["PvFloatLabel", LazyPvFloatLabel],
["PvFluid", LazyPvFluid],
["PvIconField", LazyPvIconField],
["PvInputChips", LazyPvInputChips],
["PvInputGroup", LazyPvInputGroup],
["PvInputGroupAddon", LazyPvInputGroupAddon],
["PvInputIcon", LazyPvInputIcon],
["PvInputOtp", LazyPvInputOtp],
["PvInputSwitch", LazyPvInputSwitch],
["PvKnob", LazyPvKnob],
["PvListbox", LazyPvListbox],
["PvMultiSelect", LazyPvMultiSelect],
["PvRating", LazyPvRating],
["PvSelectButton", LazyPvSelectButton],
["PvSlider", LazyPvSlider],
["PvToggleButton", LazyPvToggleButton],
["PvToggleSwitch", LazyPvToggleSwitch],
["PvTreeSelect", LazyPvTreeSelect],
["PvButtonGroup", LazyPvButtonGroup],
["PvSpeedDial", LazyPvSpeedDial],
["PvSplitButton", LazyPvSplitButton],
["PvColumn", LazyPvColumn],
["PvRow", LazyPvRow],
["PvColumnGroup", LazyPvColumnGroup],
["PvDataTable", LazyPvDataTable],
["PvDataView", LazyPvDataView],
["PvOrderList", LazyPvOrderList],
["PvOrganizationChart", LazyPvOrganizationChart],
["PvPaginator", LazyPvPaginator],
["PvPickList", LazyPvPickList],
["PvTree", LazyPvTree],
["PvTreeTable", LazyPvTreeTable],
["PvTimeline", LazyPvTimeline],
["PvVirtualScroller", LazyPvVirtualScroller],
["PvAccordion", LazyPvAccordion],
["PvAccordionPanel", LazyPvAccordionPanel],
["PvAccordionHeader", LazyPvAccordionHeader],
["PvAccordionContent", LazyPvAccordionContent],
["PvAccordionTab", LazyPvAccordionTab],
["PvCard", LazyPvCard],
["PvDeferredContent", LazyPvDeferredContent],
["PvDivider", LazyPvDivider],
["PvFieldset", LazyPvFieldset],
["PvPanel", LazyPvPanel],
["PvScrollPanel", LazyPvScrollPanel],
["PvSplitter", LazyPvSplitter],
["PvSplitterPanel", LazyPvSplitterPanel],
["PvStepper", LazyPvStepper],
["PvStepList", LazyPvStepList],
["PvStep", LazyPvStep],
["PvStepItem", LazyPvStepItem],
["PvStepPanels", LazyPvStepPanels],
["PvStepPanel", LazyPvStepPanel],
["PvTabView", LazyPvTabView],
["PvTabs", LazyPvTabs],
["PvTabList", LazyPvTabList],
["PvTab", LazyPvTab],
["PvTabPanels", LazyPvTabPanels],
["PvTabPanel", LazyPvTabPanel],
["PvToolbar", LazyPvToolbar],
["PvConfirmDialog", LazyPvConfirmDialog],
["PvConfirmPopup", LazyPvConfirmPopup],
["PvDialog", LazyPvDialog],
["PvDrawer", LazyPvDrawer],
["PvDynamicDialog", LazyPvDynamicDialog],
["PvOverlayPanel", LazyPvOverlayPanel],
["PvPopover", LazyPvPopover],
["PvSidebar", LazyPvSidebar],
["PvFileUpload", LazyPvFileUpload],
["PvBreadcrumb", LazyPvBreadcrumb],
["PvContextMenu", LazyPvContextMenu],
["PvDock", LazyPvDock],
["PvMenu", LazyPvMenu],
["PvMenubar", LazyPvMenubar],
["PvMegaMenu", LazyPvMegaMenu],
["PvPanelMenu", LazyPvPanelMenu],
["PvSteps", LazyPvSteps],
["PvTabMenu", LazyPvTabMenu],
["PvTieredMenu", LazyPvTieredMenu],
["PvMessage", LazyPvMessage],
["PvInlineMessage", LazyPvInlineMessage],
["PvToast", LazyPvToast],
["PvCarousel", LazyPvCarousel],
["PvGalleria", LazyPvGalleria],
["PvImage", LazyPvImage],
["PvAvatar", LazyPvAvatar],
["PvAvatarGroup", LazyPvAvatarGroup],
["PvBadge", LazyPvBadge],
["PvBlockUI", LazyPvBlockUI],
["PvChip", LazyPvChip],
["PvInplace", LazyPvInplace],
["PvMeterGroup", LazyPvMeterGroup],
["PvOverlayBadge", LazyPvOverlayBadge],
["PvScrollTop", LazyPvScrollTop],
["PvSkeleton", LazyPvSkeleton],
["PvProgressBar", LazyPvProgressBar],
["PvProgressSpinner", LazyPvProgressSpinner],
["PvTag", LazyPvTag],
["PvTerminal", LazyPvTerminal],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

import { PRODUCTS, COMPANIES, HIGHLIGTHS, CONTACT, HOME_META_TAGS, PRODUCT_META_TAGS } from "~/config";


export const useContent = ()=> ({
    products: useState('products', () => PRODUCTS),
    highligths: useState('highligths', () => HIGHLIGTHS),
    companies: useState('companies', () => COMPANIES),
    contact: useState('contact', () => CONTACT),
    meta:  useState('meta', () => ({home: HOME_META_TAGS, products: PRODUCT_META_TAGS})),

})

import { StoreProcessType, type Product } from '../products'

export const AUTO: Product = {
  name: 'Comparador de Auto',
  alias: 'auto',
  tagline: 'TU AUTO, NUESTRA PRIORIDAD',
  productId: 71,
  code: 'auto',
  process: 'PURCHASE',
  useComparator: true,
  bannerUrl: '/img/product-cards/auto.jpg',
  iconUrl: '/img/product-icons/auto.png',
  description:
    'El seguro de auto ofrece una protección completa para tu vehículo y terceros. Cubriendo desde daños por colisión hasta robos, vandalismo y desastres naturales como inundaciones o incendios, esta póliza te brinda una amplia tranquilidad. Con este seguro, puedes confiar de que tu vehículo estará protegido en diversas situaciones imprevistas, ofreciéndote una cobertura integral y confiable.',
  compliances: [
    'Daños parciales y totales al vehículo asegurado',
    ' Daños a propiedad de terceros',
    ' Daños por la naturaleza',
    ' Fianza judicial',
    ' Alquiler de vehículo y grúa en caso de accidente',
    ' Lesiones corporales al conductor',
  ],
  exceptions: [
    'Daños efectuados intencionalmente',
    'Robo de vehículo por un familiar o amigo',
    'Conducir bajo los efectos del alcohol',
    'Si usas el auto por fines no asegurables',
    'Siniestros provocados en caminos no habilitados para el tránsito regular de vehículos.',
  ],
  requirements: [
    'Matricula y/o BL con los datos del vehículo',
    'Cedula y licencia de conducir del asegurado',
    'Inspección de vehículo ',
  ],
  insurances: ['humano', 'mapfre_seguros', 'sura', 'universal', 'unit', 'lacolonial'],
  highlights: [
    {
      title: 'Compara',
      artWork: '/img/product-traits/auto/compara.svg',
      description: 'Compara planes entre las mejores aseguradoras del mercado.',
    },
    {
      title: 'Cotizador',
      artWork: '/img/product-traits/auto/cotiza.svg',
      description: 'Nuestras integraciones cotizan en tiempo real las primas de cada aseguradora.',
    },
    {
      title: 'Integrado',
      artWork: '/img/product-traits/auto/integrado.svg',
      description: 'Estamos conectados en tiempo real con las aseguradoras.',
    },
    {
      title: 'Compra',
      artWork: '/img/product-traits/auto/compra.svg',
      description: 'Elige y compra al instante la opción que mas vaya contigo.',
    },
  ],
}

import { StoreProcessType, type Product } from '../products'

export const TRAVELS: Product = {
  name: 'Viajes',
  tagline: 'tu aventura, segura y protegida',
  code: 'viajes',
  process: 'QUOTE',
  bannerUrl: '/img/product-cards/viajes.png',
  iconUrl: '/img/product-icons/viajes.png',
  description:
    'Siéntete seguro y sin preocupaciones protegiéndote ante cualquier imprevisto que se te presente.',
  compliances: [
    'Te compensamos por la pérdida de tu equipaje.',
    'Cubrimos los gastos en caso que haya demora en tu itinerario de vuelo.',
    'Nos hacemos cargo en caso que haya internamiento.',
    'Asistencia médica por emergencia en cualquier centro de atención afiliado a nuestra red.',
    'Repatriación en caso de fallecimiento accidental.',
  ],
  requirements: [
    'Edad minimo de ingreso: 18 años.',
    'Edad maxima de ingreso: 65 años.',
    'Edad maxima de permanencia: 75 años.',
  ],
  exceptions: [],
  insurances: ['humano'],
  highlights: [
    {
      title: 'Asistencia',
      artWork: '/img/product-traits/travels/asistencia.svg',
      description: 'Asistencia medica y hospitalaria por accidente o enfermedad.',
    },
    {
      title: 'Cancelación',
      artWork: '/img/product-traits/travels/cancelado.svg',
      description: 'Cubre gastos por vuelo cancelado o demorado.',
    },
    {
      title: 'Hotel',
      artWork: '/img/product-traits/travels/hotel.svg',
      description: 'Cubre gastos de hotel por convalecencia',
    },
    {
      title: 'Equipaje',
      artWork: '/img/product-traits/travels/equipaje.svg',
      description: 'Compensación por pérdida de equipaje.',
    },
  ],
}

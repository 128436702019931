import { StoreProcessType, type Product, type ProductPlan } from '../products'

export const PERSONAL_ACCIDENTS: Product = {
  productId: 28,
  name: 'Accidentes Personales',
  alias: 'Accidentes',
  tagline: 'protegete ante lo inesperado',
  code: 'accidentes',
  process: 'PURCHASE',
  bannerUrl: '/img/product-cards/accidentes.png',
  iconUrl: '/img/product-icons/accidentes.png',
  description:
    'En caso de fallecimiento accidental, desmembramiento o una incapacidad, la aseguradora otorga un beneficio económico a los beneficiarios designados o herederos legales (hasta el límite asegurado).',
  compliances: [
    'Fallecimiento accidental.',
    'En caso de una incapacidad total o permanente recibes la suma contratada.',
    'Desmembramiento.',
  ],
  exceptions: [
    'Si el accidente ocurre bajo efectos del alcohol o droga.',
    'Si las lesiones son provocadas por ti mismo.',
    'Incapacidad o internamiento causado por accidente, enfermedad, lesión o condición pre existente, originada antes del inicio de vigencia de la cobertura.',
    'Participación en cualquier acto de guerra, huelga o duelos.',
  ],
  requirements: [
    'Edad minimo de ingreso: 18 años.',
    'Edad maxima de ingreso: 65 años.',
    'Edad maxima de permanencia: 75 años.',
  ],
  insurances: ['humano'],
  highlights: [
    {
      title: 'Invalidez',
      artWork: '/img/product-traits/personalAccidents/invalidez.svg',
      description:
        'Te pagará la suma asegurada, al ocurrir un accidente que te ocasione una incapacidad total y permanente.',
    },
    {
      title: 'Muerte Accidental',
      artWork: '/img/product-traits/personalAccidents/muerte_accidental.svg',
      description: 'Desembolso del monto asegurado en caso de muerte por accidente.',
    },
    {
      title: 'Beneficiarios',
      artWork: '/img/product-traits/personalAccidents/beneficiarios.svg',
      description: 'Designa tus beneficiarios al momento de contratar tu póliza.',
    },
    {
      title: 'Desmembramiento',
      artWork: '/img/product-traits/personalAccidents/desmembramiento.svg',
      description:
        'Te pagará el valor indicado en la tabla de beneficios de acuerdo a la perdida corporal.',
    },
  ],
  plans: [
    {
      name: 'LITE',
      code: 'LITE',
      description: '',
      highlights: {
        description: [
          {
            label: 'FALLECIMIENTO ACCIDENTAL:',
            detail: 'RD$250,000',
          },
          {
            label: 'DESMEMBRAMIENTO:',
            detail: 'RD$250,000',
          },
          {
            label: 'INCAPACIDAD TOTAL O Y PERMANENTE:',
            detail: 'RD$250,000',
          },
        ],
      },
      price: 30,
      subscription: 'monthly',
    },
    {
      name: 'FULL',
      code: 'FULL',
      description: '',
      highlights: {
        description: [
          {
            label: 'FALLECIMIENTO ACCIDENTAL:',
            detail: 'RD$500,000',
          },
          {
            label: 'DESMEMBRAMIENTO:',
            detail: 'RD$500,000',
          },
          {
            label: 'INCAPACIDAD TOTAL O Y PERMANENTE:',
            detail: 'RD$500,000',
          },
        ],
      },
      price: 61,
      subscription: 'monthly',
    },
    {
      name: 'ULTRA',
      code: 'ULTRA',
      description: '',
      highlights: {
        description: [
          {
            label: 'FALLECIMIENTO ACCIDENTAL:',
            detail: 'RD$1,000,000',
          },
          {
            label: 'DESMEMBRAMIENTO:',
            detail: 'RD$1,000,000',
          },
          {
            label: 'INCAPACIDAD TOTAL O Y PERMANENTE:',
            detail: 'RD$1,000,000',
          },
        ],
      },
      price: 122,
      subscription: 'monthly',
    },
  ],
}

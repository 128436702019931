export const CONTACT = {
  address: {
    icon: 'mdi--map-marker',
    text: 'Nuestra oficina',
    contact:
      'Torre Westpark, Nivel 2, Av. Tiradentes, esq. Av. Gustavo Mejía Ricart, Ensanche Naco, Sto. Dgo., R.D.',
    href: 'https://goo.gl/maps/S7ohWWtYfN4x6Pgy9',
  },
  others: [
    {
      icon: 'mdi--whatsapp',
      text: 'WhatsApp',
      contact: '(809) 223-1773',
      href: 'https://wa.me/+18092231773',
      type: 'c-b',
    },
    {
      icon: 'ph--phone-fill',
      text: 'Oficina',
      contact: '(809) 683-0587',
      href: 'tel:(809) 683-0587',
      type: 'c-b',
    },
    {
      icon: 'ph--envelope-simple-fill',
      text: 'Correo',
      contact: 'operaciones@contigo.do',
      href: 'mailto:operaciones@contigo.do?subject=Contacto via Pagina Web',
      type: 'c-b',
    },
    {
      icon: 'mdi--facebook-box',
      text: 'Facebook',
      contact: 'contigo.do',
      href: 'https://www.facebook.com/contigo.do',
      type: 'social-media',
    },
    {
      icon: 'mdi--instagram',
      text: 'Instagram',
      contact: 'contigo.do',
      href: 'https://www.instagram.com/contigo.do',
      type: 'social-media',
    },
    {
      icon: 'mdi--twitter-box',
      text: 'Twitter',
      contact: 'contigo.do',
      href: 'https://twitter.com/ContigoDo',
      type: 'social-media',
    },
  ],
} as const

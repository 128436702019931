<script lang="ts" setup>
useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/img/logo.png',
    },
    {
      id: 'gilroy-webfonts-kit',
      rel: 'stylesheet',
      href: () => '/GilroyWebfontsKit.css',
    },
  ],
})
const isMounted = ref(false) //To avoid hydration warnings.
onMounted(() => (isMounted.value = true))
</script>

<template>
  <div v-if="isMounted">
    <div
      class="absolute z-[-10] h-[500px] w-full bg-gradient-to-t from-primary-950 to-primary-700"
    ></div>
    <NuxtLayout>
      <NuxtPage v-if="isMounted" />
    </NuxtLayout>
    <PvToast />
  </div>
  <span class="icon-[ph--chat-centered-dots-fill] hidden" />
  <span class="icon-[ph--pencil-simple-line-fill] hidden" />
  <span class="icon-[ph--book-open-fill] hidden" />
  <span class="icon-[ph--user-rectangle-fill] hidden" />
  <span class="icon-[mdi--map-marker] hidden" />
  <span class="icon-[mdi--whatsapp] hidden" />
  <span class="icon-[ph--phone-fill] hidden" />
  <span class="icon-[ph--envelope-simple-fill] hidden" />
  <span class="icon-[ph--check-circle-bold] hidden" />
  <span class="icon-[ph--x-circle-bold] hidden" />
  <span class="icon-[ph--info-bold] hidden" />
  <span class="icon-[ph--calendar-dots-fill] hidden" />
  <span class="icon-[ph--gender-intersex-fill] hidden" />
  <span class="icon-[ph--device-mobile-fill] hidden" />
  <span class="icon-[ph--info-fill] hidden" />
  <span class="icon-[mdi--facebook-box] hidden" />
  <span class="icon-[mdi--instagram] hidden" />
  <span class="icon-[mdi--twitter-box] hidden" />
  <span class="icon-[ph--car-fill] hidden" />
  <span class="icon-[ph--seal-check-fill] hidden" />
  <span class="icon-[ph--thumbs-up-fill] hidden" />
  <span class="icon-[ph--shield-check-fill] hidden" />
  <span class="icon-[ph--file-text-fill] hidden" />
  <span class="icon-[ph--bell-simple-ringing-fill] hidden" />
</template>

<style></style>
